import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { toast } from 'react-toastify';

import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import {
  Avatar,
  DialogContent,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField as MuiTextField,
  Tooltip,
  Typography,
} from '@mui/material';
import Button from 'src/components/Button';
import CustomDialog from 'src/components/CustomDialog';
import { CustomDialogRef } from 'src/components/CustomDialog/interfaces';
import { Item } from 'src/components/Form/Select/interfaces';
import LinkButton from 'src/components/LinkButton';
import WSOmnichannel from 'src/services/websocket/omnichannel';
import {
  ChatMessage,
  OmnichannelCallback,
  OmnichannelListenEvents,
} from 'src/services/websocket/omnichannel/interfaces';
import masks from 'src/utils/masks';
import OminichannelUtils from 'src/utils/omnichannel';

import OmnichannelContext from '../../context';
import ChatMenu from './Menu';
import Message, { MessageProps } from './Message';
import MessageSender from './MessageSender';
import {
  Container,
  Content,
  InputContainer,
  MessagesContainer,
  SendContainer,
} from './styles';
import UploadFiles from './UploadFiles';

const messageTypeItems: Item[] = [
  { key: 1, label: 'WhatsApp', value: 'whatsapp' },
  // { key: 2, label: 'SMS', value: 'sms' },
];

type ChatContentProps = {
  hideHeader?: boolean;
  hideBack?: boolean;
  hideMenu?: boolean;
};

const ChatContent: FC<ChatContentProps> = ({
  hideHeader,
  hideBack,
  hideMenu,
}) => {
  const {
    selectedChat,
    setSelectedChat,
    selectedChatMessages,
    setSelectedChatMessages,
    answerMessage,
    setAnswerMessage,
    upload,
    adminView,
  } = useContext(OmnichannelContext);
  const endRef = useRef<HTMLDivElement>(null);
  const clientDialogRef = useRef<CustomDialogRef>(null);
  const scrollToEndRef = useRef(true);
  const scrollToMessageAfterLoad = useRef<string>();
  const [canLoadMessage, setCanLoadMessage] = useState(true);
  const [messageChannel, setMessageChannel] = useState('whatsapp');
  // const isChatActive = selectedChat?.status === 'active';

  const listChatMessagesCb = useCallback<OmnichannelCallback<ChatMessage[]>>(
    (result) => {
      if (result.error) {
        toast.error(result.error.message);
      } else {
        setSelectedChatMessages((data) => [...result.data, ...data]);

        setCanLoadMessage(result.data.length > 0);

        if (scrollToMessageAfterLoad.current) {
          setTimeout(() => {
            OminichannelUtils.scrollToChatMessage(
              scrollToMessageAfterLoad.current,
            );
            scrollToMessageAfterLoad.current = undefined;
          }, 100);
        } else if (scrollToEndRef.current) {
          setTimeout(() => {
            endRef.current?.scrollIntoView({ behavior: 'smooth' });
          }, 100);
        }
      }
    },
    [setSelectedChatMessages],
  );

  useEffect(() => {
    if (selectedChat) {
      scrollToEndRef.current = true;

      const onMessageReceived: OmnichannelListenEvents['message_received'] = (
        message,
      ) => {
        if (selectedChat._id === message.chat) {
          setSelectedChatMessages((data) => [...data, message]);

          if (scrollToEndRef.current || message.direction === 'outgoing') {
            endRef.current?.scrollIntoView({ behavior: 'smooth' });
          }

          if (!adminView) {
            WSOmnichannel.socket?.emit('view_chat', selectedChat._id);
          }
        }
      };

      const onReactionsReceived: OmnichannelListenEvents['reactions_received'] = (
        message,
      ) => {
        if (selectedChat._id === message.chat) {
          setSelectedChatMessages((data) =>
            data.map((chatMessage) => {
              if (chatMessage._id === message._id) {
                return message;
              }
              return chatMessage;
            }),
          );
        }
      };

      const onNewChat: OmnichannelListenEvents['new_chat'] = (newChat) => {
        if (newChat._id === selectedChat._id) {
          setSelectedChat(newChat);
        }
      };

      WSOmnichannel.socket?.emit(
        'list_chat_messages',
        {
          chat_id: selectedChat._id,
        },
        listChatMessagesCb,
      );
      WSOmnichannel.socket?.on('message_received', onMessageReceived);
      WSOmnichannel.socket?.on('reactions_received', onReactionsReceived);
      WSOmnichannel.socket?.on('new_chat', onNewChat);
      if (!adminView) {
        WSOmnichannel.socket?.emit('view_chat', selectedChat._id);
      }

      return () => {
        WSOmnichannel.socket?.removeListener(
          'message_received',
          onMessageReceived,
        );
        WSOmnichannel.socket?.removeListener(
          'reactions_received',
          onReactionsReceived,
        );
        WSOmnichannel.socket?.removeListener('new_chat', onNewChat);
        setSelectedChatMessages([]);
      };
    }
  }, [
    selectedChat,
    setSelectedChat,
    setSelectedChatMessages,
    listChatMessagesCb,
    adminView,
  ]);

  const loadMessages = useCallback(
    (untilMessage?: ChatMessage) => {
      if (selectedChat && endRef.current) {
        scrollToMessageAfterLoad.current = untilMessage?._id;
        WSOmnichannel.socket?.emit(
          'list_chat_messages',
          {
            chat_id: selectedChat._id,
            last_sended_at: selectedChatMessages.slice().shift()?.sended_at,
            until_sended_at: untilMessage?.sended_at,
          },
          listChatMessagesCb,
        );
      }
    },
    [selectedChat, selectedChatMessages, listChatMessagesCb],
  );

  function closeChat() {
    setSelectedChat(undefined);
  }

  function selectMessageToReply(message: ChatMessage) {
    // if (!isChatActive) return;
    setMessageChannel(message.channel);
    setAnswerMessage(message);
  }

  function clearMessageToReply() {
    setAnswerMessage(undefined);
  }

  const onClickReplyMessage: MessageProps['onReplyClick'] = (
    referenceMessage,
  ) => {
    const messageVisible = selectedChatMessages.find(
      (chatMessage) => chatMessage._id === referenceMessage._id,
    );
    if (messageVisible) {
      return OminichannelUtils.scrollToChatMessage(messageVisible._id);
    }

    loadMessages(referenceMessage);
  };

  function renderAvatar() {
    let letters;

    if (selectedChat?.client?.username) {
      const exploded = selectedChat.client.username.split(' ');
      if (exploded.length === 1) {
        letters = exploded.pop()?.substr(0, 2);
      } else {
        letters = `${exploded.shift()?.charAt(0) || ''}${
          exploded.pop()?.charAt(0) || ''
        }`;
      }
    }

    return (
      <Avatar alt={letters} src={selectedChat?.image}>
        {letters}
      </Avatar>
    );
  }

  return (
    <Container square>
      {!hideHeader && (
        <>
          <List disablePadding>
            <ListItem>
              {!hideBack && (
                <ListItemIcon>
                  <IconButton size="small" onClick={closeChat}>
                    <ArrowBackIcon fontSize="inherit" />
                  </IconButton>
                </ListItemIcon>
              )}

              <ListItemAvatar>{renderAvatar()}</ListItemAvatar>

              <ListItemText
                primary={
                  <>
                    Conversando com {selectedChat?.client?.username}{' '}
                    <IconButton
                      size="small"
                      onClick={() => clientDialogRef.current?.show()}
                      sx={{ p: 0 }}
                    >
                      <InfoIcon fontSize="inherit" />
                    </IconButton>
                  </>
                }
                secondary={selectedChat?.description}
              />

              {!hideMenu && <ChatMenu />}
            </ListItem>
          </List>

          <Divider />
        </>
      )}

      <Content>
        <MessagesContainer
          onScroll={(e) => {
            const element = e.currentTarget;
            scrollToEndRef.current =
              element.scrollTop === element.scrollHeight - element.offsetHeight;
          }}
        >
          {canLoadMessage && (
            <Button
              size="small"
              color="primary"
              startIcon={<AddIcon fontSize="inherit" />}
              onClick={() => loadMessages()}
            >
              Mensagens
            </Button>
          )}

          {selectedChatMessages.map((message) => (
            <Message
              key={message._id}
              message={message}
              onReply={() => selectMessageToReply(message)}
              onReplyClick={onClickReplyMessage}
            />
          ))}

          <div ref={endRef} />
        </MessagesContainer>

        <Divider />

        {/* {isChatActive && ( */}
        <InputContainer>
          <MuiTextField
            select
            label={answerMessage ? 'Respondendo Por:' : 'Enviar Por:'}
            value={messageChannel}
            variant="outlined"
            color="primary"
            size="small"
            onChange={(e) => setMessageChannel(e.target.value)}
            // disabled={!!answerMessage}
            disabled
            style={{ display: 'none' }}
          >
            {messageTypeItems.map((item) => (
              <MenuItem key={item.key} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </MuiTextField>

          {!!answerMessage && (
            <Tooltip title="Cancelar Resposta">
              <IconButton
                color="error"
                type="button"
                size="small"
                onClick={clearMessageToReply}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          )}

          <SendContainer>
            {!!answerMessage && (
              <Message key={answerMessage._id} message={answerMessage} />
            )}

            <MessageSender type={messageChannel} />
          </SendContainer>
        </InputContainer>
        {/* )} */}

        {upload && <UploadFiles channel={messageChannel} />}
      </Content>

      {selectedChat?.client?.username && (
        <CustomDialog
          ref={clientDialogRef}
          title={`Informações de ${selectedChat.client.username}`}
        >
          <DialogContent>
            <Typography>
              <strong>Tipo: </strong>
              {selectedChat.client.type === 'F'
                ? 'Pessoa Física'
                : selectedChat.client.type === 'J'
                ? 'Pessoa Jurídica'
                : 'Contato'}
            </Typography>
            <Typography>
              <strong>Email:</strong> {selectedChat.client.email}
            </Typography>
            <Typography>
              <strong>Telefone: </strong>
              {masks.strictPhoneOrCellphone(selectedChat.client.phone)}
            </Typography>

            <div style={{ textAlign: 'right' }}>
              <LinkButton
                href={`/clientes/${selectedChat.client.id}`}
                target="_blank"
                variant="contained"
                startIcon={<EditIcon />}
              >
                Editar
              </LinkButton>
            </div>
          </DialogContent>
        </CustomDialog>
      )}
    </Container>
  );
};

export default ChatContent;
