import { io, Socket } from 'socket.io-client';
import { store } from 'src/store';

import { OmnichannelEmitEvents, OmnichannelListenEvents } from './interfaces';

class WebSocketOmnichannel {
  public socket?: Socket<OmnichannelListenEvents, OmnichannelEmitEvents>;
  private connected = false;

  public connect(zApiInstanceId: number) {
    if (this.connected) return;

    this.socket = io(
      `${process.env.REACT_APP_WS_URL}/omnichannel-${zApiInstanceId}`,
      {
        auth: { token: store.getState().auth.token },
        reconnectionDelay: 5000,
        reconnectionAttempts: 3,
      },
    );

    this.connected = true;
  }

  public disconnect() {
    if (!this.connected) return;

    this.socket?.disconnect();
    this.connected = false;
  }
}

const WSOmnichannel = new WebSocketOmnichannel();

export default WSOmnichannel;
