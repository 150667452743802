import React, { useContext, useEffect, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { useHistory, useLocation } from 'react-router-dom';

import { Funnel, Opportunity } from 'src/modules/attendance/@types/models';
import OpportunityCard from 'src/modules/attendance/components/OpportunityCard';
import attendanceApi from 'src/modules/attendance/services/api';
import { IResponseAPIFilter } from 'src/services/api/interfaces';
import { handleApiResponseErrors } from 'src/utils/errors';

import { OpportunitiesContext } from '../../context';
import { Column, ColumnBody, ColumnHeader, Container } from './styles';

type ViewColumnProps = {
  funnel: Funnel;
};

function getOpportunityFilterParams(search: string) {
  const params = new URLSearchParams(search);
  params.delete('page');
  params.delete('page');

  return params;
}

const ViewColumn: React.FC<ViewColumnProps> = ({ funnel }) => {
  const location = useLocation();
  const {
    data: stepsResponse,
  } = attendanceApi.admin.common.useFunnelStepsQuery(funnel.id);
  const {
    data: opportunitiesResponse,
    refetch,
  } = attendanceApi.admin.funnels.opportunities.useFunnelOpportunitiesQuery(
    funnel.id,
    {
      requestConfig: {
        params: getOpportunityFilterParams(location.search),
      },
    },
  );
  const {
    data: responseUnreads,
  } = attendanceApi.admin.funnels.unreadChats.useUnreadChatsQuery(funnel.id);
  const [opportunitites, setOpportunities] = useState<
    IResponseAPIFilter<Opportunity> | undefined
  >(opportunitiesResponse?.data);
  const { needsReload, setNeedsReload, setOpportunitiesTotals } = useContext(
    OpportunitiesContext,
  );
  const [opportunityInDrag, setOpportunityInDrag] = useState<string>();
  const history = useHistory();

  const stepsOpportunities: { [key: number]: Opportunity[] } = {};
  stepsResponse?.data.forEach((step) => {
    stepsOpportunities[step.id] =
      opportunitites?.filter(
        (opportunity) => opportunity.funnel_step_id === step.id,
      ) || [];
  });

  function handleCardClick(opportunity: Opportunity) {
    history.push(`${location.pathname}/${funnel.id}/${opportunity.id}`);
  }

  async function handleDragEnd(result: DropResult) {
    const { source, destination, draggableId } = result;

    if (source.droppableId === destination?.droppableId) return;

    const opportunity = opportunitites?.find(
      (opportunity) => opportunity.id === Number(draggableId),
    );
    if (!opportunity) return;

    try {
      setOpportunityInDrag(draggableId);

      // update list state do not flick drop
      setOpportunities((state) => {
        state?.forEach((stateOpport) => {
          if (stateOpport.id === Number(draggableId)) {
            stateOpport.funnel_step_id = Number(destination?.droppableId);
          }
          return stateOpport;
        });

        return state;
      });

      await attendanceApi.admin.funnels.opportunities.updateFunnelOpportunity(
        funnel.id,
        draggableId,
        { funnel_step_id: destination?.droppableId },
      );
      // await refetch();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao mover opportunidade.');
      // move back if error
      setOpportunities((state) => {
        state?.forEach((stateOpport) => {
          if (stateOpport.id === Number(draggableId)) {
            stateOpport.funnel_step_id = Number(source.droppableId);
          }
          return stateOpport;
        });

        return state;
      });
    } finally {
      setOpportunityInDrag(undefined);
    }
  }

  useEffect(() => {
    refetch();
  }, [location.search, refetch]);

  useEffect(() => {
    async function handleReload() {
      await refetch();
      setNeedsReload(false);
    }

    if (needsReload) handleReload();
  }, [needsReload, refetch, setNeedsReload]);

  useEffect(() => {
    setOpportunities(opportunitiesResponse?.data);
    setOpportunitiesTotals({
      qty: opportunitiesResponse?.data.length || 0,
      sum:
        opportunitiesResponse?.data.reduce((sum, opp) => sum + opp.amount, 0) ||
        0,
    });
  }, [opportunitiesResponse, setOpportunitiesTotals]);

  return (
    <Container>
      <DragDropContext onDragEnd={handleDragEnd}>
        {stepsResponse?.data?.map((step) => (
          <Column key={step.id}>
            <ColumnHeader square elevation={3}>
              {step.name} ({stepsOpportunities[step.id].length})
            </ColumnHeader>

            <Droppable droppableId={String(step.id)} type="step">
              {(provided) => (
                <ColumnBody
                  square
                  elevation={3}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {stepsOpportunities[step.id].map((opportunity, index) => (
                    <Draggable
                      key={opportunity.id}
                      draggableId={String(opportunity.id)}
                      index={index}
                      isDragDisabled={!!opportunityInDrag}
                    >
                      {(provided) => (
                        <OpportunityCard
                          opportunity={opportunity}
                          messageUnread={
                            !!responseUnreads?.data.find(
                              (unread) =>
                                unread.client_id === opportunity.client_id,
                            )
                          }
                          onClick={handleCardClick}
                          dragProps={provided.draggableProps}
                          dragHandleProps={provided.dragHandleProps}
                          ref={provided.innerRef}
                          loading={Number(opportunityInDrag) === opportunity.id}
                        />
                      )}
                    </Draggable>
                  ))}
                </ColumnBody>
              )}
            </Droppable>
          </Column>
        ))}
      </DragDropContext>
    </Container>
  );
};

export default ViewColumn;
