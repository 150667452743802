import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';

import IZApiInstance from 'src/interfaces/models/IZApiInstance';
import PrivateContext from 'src/routes/Private/PrivateContext';
import apiAdminPublic from 'src/services/api/adminPublic';
import WSOmnichannel from 'src/services/websocket/omnichannel';
import {
  Chat,
  ChatMessage,
} from 'src/services/websocket/omnichannel/interfaces';
import { handleApiResponseErrors } from 'src/utils/errors';
import NotificationUtils from 'src/utils/notifications';
import OminichannelUtils from 'src/utils/omnichannel';

import OmnichannelChat from './Chat';
import OmnichannelContext, { Upload } from './context';
import { Container } from './styles';

type OmnichannelRouteParams = {
  departmentId: string;
};

const Omnichannel: React.FC = () => {
  const { startLayoutLoading, stopLayoutLoading } = useContext(PrivateContext);
  const params = useParams<OmnichannelRouteParams>();
  const [zApiInstance, setZApiInstance] = useState<IZApiInstance>();
  const [chats, setChats] = useState<Chat[]>([]);
  const [selectedChat, setSelectedChat] = useState<Chat>();
  const [selectedChatMessages, setSelectedChatMessages] = useState<
    ChatMessage[]
  >([]);
  const [answerMessage, setAnswerMessage] = useState<ChatMessage>();
  const [upload, setUpload] = useState<Upload | null>(null);
  const [adminView, setAdminView] = useState(false);

  const loadData = useCallback(
    async (id: string) => {
      try {
        startLayoutLoading();

        const { data } = await apiAdminPublic.getZApiInstance(id);

        setZApiInstance(data);
      } catch (error) {
        handleApiResponseErrors(
          error.response,
          'Erro ao buscar Instância Whatsapp.',
        );
      } finally {
        stopLayoutLoading();
      }
    },
    [startLayoutLoading, stopLayoutLoading],
  );

  useEffect(() => {
    loadData(params.departmentId);
  }, [loadData, params.departmentId]);

  useEffect(() => {
    if (!zApiInstance) {
      setChats([]);
      return;
    }
    WSOmnichannel.connect(zApiInstance.id);

    NotificationUtils.requestPermission();

    WSOmnichannel.socket?.on('connect_error', () => {
      toast.error('Não foi possível conectar ao WebSocket');
    });

    WSOmnichannel.socket?.emit('list_chats', (result) => {
      if (result.error) {
        toast.error(result.error.message);
      } else {
        setChats(result.data);
      }
    });

    WSOmnichannel.socket?.on('new_chat', (newChat) => {
      setChats((data) => {
        const exists = data.find((chat) => chat._id === newChat._id);
        const hasAccess = OminichannelUtils.hasAccessToChat(newChat);

        if (hasAccess) {
          if (!exists) return [newChat, ...data]; // if not find, add the new chat
          return data.map((chat) =>
            chat._id === newChat._id ? newChat : chat,
          );
        } else {
          // remove chat if lost access
          if (exists) {
            return data.filter((chat) => chat._id !== newChat._id);
          }

          return data; // keep value if not exists
        }
      });
    });

    WSOmnichannel.socket?.on('message_received', (message) => {
      setChats((data) =>
        data
          .map((chat) => {
            if (chat._id === message.chat) {
              chat.last_message = message;
              if (message.direction === 'incoming') {
                NotificationUtils.createNotification({
                  title: `Mensagem de ${chat.client?.username}`,
                  options: {
                    body: OminichannelUtils.getChatMessageText(message),
                  },
                });
              }
            }

            return chat;
          })
          .sort((a, b) => {
            const aSended = a.last_message?.sended_at || '';
            const bSended = b.last_message?.sended_at || '';

            if (aSended > bSended) {
              return -1;
            }
            if (aSended < bSended) {
              return 1;
            }
            return 0;
          }),
      );
    });

    WSOmnichannel.socket?.on('view_chat', (viewedChat) => {
      setChats((data) =>
        data.map((chat) => {
          if (chat._id === viewedChat._id) {
            return viewedChat;
          }
          return chat;
        }),
      );
    });

    return () => {
      WSOmnichannel.disconnect();
    };
  }, [zApiInstance]);

  useEffect(() => {
    if (!chats.find((chat) => chat._id === selectedChat?._id)) {
      setSelectedChat(undefined);
    }
  }, [chats, selectedChat]);

  useEffect(() => {
    setUpload(null);
  }, [selectedChat]);

  return (
    <OmnichannelContext.Provider
      value={{
        zApiInstance,
        setZApiInstance,
        chats,
        selectedChat,
        setSelectedChat,
        selectedChatMessages,
        setSelectedChatMessages,
        answerMessage,
        setAnswerMessage,
        upload,
        setUpload,
        adminView,
        setAdminView,
      }}
    >
      <Container>
        {/* {!department && (
          <div>
            <Card>
              <CardContent>
                <Form onSubmit={() => null}>
                  <AutocompleteDepartments
                    name="department_id"
                    label="Departamento"
                    requestConfig={{ params: { has_z_api_instance: true } }}
                    onChange={(value) => {
                      if (!value || Array.isArray(value)) {
                        setDepartment(undefined);
                        return;
                      }

                      setDepartment(value.data);
                    }}
                  />
                </Form>

                <Typography variant="caption" color="GrayText">
                  São mostrados apenas os departamentos que possuem Instância
                  WhatsApp.
                </Typography>
              </CardContent>
            </Card>
          </div>
        )} */}

        {zApiInstance && <OmnichannelChat />}
      </Container>
    </OmnichannelContext.Provider>
  );
};

export default Omnichannel;
