import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { COMMON_QUERY_KEYS } from 'src/constants/query';
import IZApiInstance from 'src/interfaces/models/IZApiInstance';
import { adminPublicAxios } from 'src/services/api/adminPublic';
import {
  IApiUseQueryData,
  IApiUseQueryFnData,
  IAxiosResponseAPI,
} from 'src/services/api/interfaces';

export async function getZApiInstances(
  config?: AxiosRequestConfig,
): Promise<IAxiosResponseAPI<IZApiInstance>> {
  const response = await adminPublicAxios.get('z-api-instances', config);

  return response;
}

export function useZApiInstancesQuery(data?: IApiUseQueryData<IZApiInstance>) {
  return useQuery<IApiUseQueryFnData<IZApiInstance>>(
    [COMMON_QUERY_KEYS.Z_API_INSTANCES, data?.requestConfig?.params],
    () => getZApiInstances(data?.requestConfig),
    data?.queryOptions,
  );
}

export async function getZApiInstance(
  id: number | string,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<IZApiInstance>> {
  const response = await adminPublicAxios.get(`z-api-instances/${id}`, config);

  return response;
}
