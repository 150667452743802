import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

// import AddIcon from '@mui/icons-material/Add';
// import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
} from '@mui/material';
import Button from 'src/components/Button';
import { Opportunity } from 'src/modules/attendance/@types/models';
import OpportunityCard from 'src/modules/attendance/components/OpportunityCard';
import attendanceApi from 'src/modules/attendance/services/api';
import { Chat } from 'src/services/websocket/omnichannel/interfaces';

// import NewForm from './NewForm';

export type ChatClientOpportunitiesDialogProps = {
  chat: Chat;
  onClose: () => void;
} & DialogProps;

const ChatClientOpportunitiesDialog: React.FC<ChatClientOpportunitiesDialogProps> = ({
  chat,
  onClose,
  ...rest
}) => {
  const {
    data: response,
    refetch,
  } = attendanceApi.admin.common.useClientOpportunitiesQuery(chat.client_id, {
    requestConfig: { params: { status: 'open' } },
    queryOptions: { enabled: false },
  });
  // const [selectedOpportunity, setSelectedOpportunity] = useState<Opportunity>();
  // const [showNewForm, setShowNewForm] = useState(false);
  // const [loading, setLoading] = useState(false);

  // const hasChatOpportunity = !!chat.opportunity_id;
  // const isChatActive = chat.status === 'active';

  const handleClose: DialogProps['onClose'] = (_, reason) => {
    if (reason === 'backdropClick') return;
    // if (loading) return;
    onClose();
  };

  // function bindToOpportunity() {
  //   try {
  //     setLoading(true);

  //     if (!selectedOpportunity) {
  //       return toast.error('Selecione uma oportunidade.');
  //     }

  //     WSOmnichannel.socket?.emit(
  //       'bind_opportunity',
  //       {
  //         chat_id: chat._id,
  //         opportunity_id: selectedOpportunity.id,
  //       },
  //       (result) => {
  //         if (result.error) {
  //           toast.error(result.error.message);
  //         } else {
  //           toast.success('Chat vinculado a oportunidade.');
  //           onClose();
  //         }
  //       },
  //     );
  //   } catch (error) {
  //     handleApiResponseErrors(
  //       error.response,
  //       'Erro ao vincular a oportunidade.',
  //     );
  //   } finally {
  //     setLoading(false);
  //   }
  // }

  function handleOpportunityClick(opportunity: Opportunity) {
    // if (!hasChatOpportunity) {
    //   setSelectedOpportunity((state) =>
    //     state?.id === opportunity.id ? undefined : opportunity,
    //   );
    // } else {
    goToOpportunityDetails(opportunity);
    // }
  }

  async function goToOpportunityDetails(opportunity: Opportunity) {
    try {
      if (!opportunity.step?.funnel_id) throw new Error();

      await attendanceApi.admin.funnels.opportunities.getFunnelOpportunity(
        opportunity.step.funnel_id,
        opportunity.id,
      );

      window.open(
        `/atendimento/oportunidades/${opportunity.step.funnel_id}/${opportunity.id}`,
      );
    } catch (error) {
      toast.error('Você não possui acesso a oportunidade para poder abri-la');
    }
  }

  useEffect(() => {
    if (!rest.open) return;
    refetch();
    // setShowNewForm(false);
  }, [rest.open, refetch]);

  // useEffect(() => {
  //   const chatOpportunity = response?.data.find(
  //     (opp) => opp.id === chat.opportunity_id,
  //   );

  //   setSelectedOpportunity(chatOpportunity);
  // }, [response?.data, chat]);

  function renderActionButtons() {
    // if (hasChatOpportunity) return null;
    // return selectedOpportunity ? (
    //   <Button
    //     startIcon={<CheckIcon />}
    //     color="success"
    //     variant="contained"
    //     onClick={bindToOpportunity}
    //     loading={loading}
    //     disabled={!isChatActive}
    //   >
    //     Vincular a Oportunidade
    //   </Button>
    // ) : (
    //   <Button
    //     startIcon={<AddIcon />}
    //     color="success"
    //     variant="contained"
    //     onClick={() => setShowNewForm((value) => !value)}
    //     disabled={!isChatActive}
    //   >
    //     Abrir Oportunidade
    //   </Button>
    // );
  }

  return (
    <Dialog fullWidth onClose={handleClose} {...rest}>
      {/* {showNewForm ? (
        <NewForm
          chat={chat}
          onBack={() => setShowNewForm(false)}
          onSuccess={() => refetch()}
        />
      ) : (
        <> */}
      <DialogTitle align="center">
        Oportunidades em aberto do Cliente
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={1} justifyContent="center" alignItems="center">
          {response?.data.map((opportunity) => (
            <Grid item xs={12} md={4} key={opportunity.id}>
              <OpportunityCard
                opportunity={opportunity}
                onClick={handleOpportunityClick}
                // selected={opportunity.id === selectedOpportunity?.id}
                // disableClick={hasChatOpportunity}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          startIcon={<CloseIcon />}
          color="inherit"
          variant="text"
          onClick={(e) => handleClose(e, 'escapeKeyDown')}
          // disabled={loading}
        >
          Fechar
        </Button>

        {renderActionButtons()}
      </DialogActions>
      {/* </>
      )} */}
    </Dialog>
  );
};

export default ChatClientOpportunitiesDialog;
