import React, { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Grid, Tooltip, Typography } from '@mui/material';
import { GridColDef, GridRowParams } from '@mui/x-data-grid';
import { columnDateTime } from 'src/components/Table/types';
import useApiQueryPagination from 'src/hooks/useApiQueryPagination';
import { Funnel, Opportunity } from 'src/modules/attendance/@types/models';
import OpportunityHasProducts from 'src/modules/attendance/components/OpportunityHasProducts';
import OpportunityUnread from 'src/modules/attendance/components/OpportunityUnread';
import { OPPORTUNITY_STATUS_LABEL } from 'src/modules/attendance/constants';
import { opportunityStatusTextColor } from 'src/modules/attendance/helpers';
import attendanceApi from 'src/modules/attendance/services/api';
import { formatDate } from 'src/utils/helpers';

import { OpportunitiesContext } from '../../context';
import { StyledTable } from './styles';

type ViewListProps = {
  funnel: Funnel;
};

const ViewList: React.FC<ViewListProps> = ({ funnel }) => {
  const {
    data: response,
    isLoading,
    handlePageChange,
    handlePageSizeChange,
    refetch,
  } = useApiQueryPagination<Opportunity>(
    (queryData) =>
      attendanceApi.admin.funnels.opportunities.useFunnelOpportunitiesQuery(
        funnel.id,
        queryData,
      ),
    {
      preload: ['step'],
      order_by: [{ column: 'last_interest_at', direction: 'desc' }],
    },
  );
  const {
    data: responseUnreads,
  } = attendanceApi.admin.funnels.unreadChats.useUnreadChatsQuery(funnel.id);
  const { needsReload, setNeedsReload, setOpportunitiesTotals } = useContext(
    OpportunitiesContext,
  );
  const history = useHistory();
  const location = useLocation();

  function handleRowClick({ row }: GridRowParams<Opportunity>) {
    history.push(`${location.pathname}/${funnel.id}/${row.id}`);
  }

  const columns: GridColDef<Opportunity>[] = [
    { field: 'id', headerName: 'ID' },
    {
      field: 'name',
      headerName: 'Título',
      flex: 1,
      minWidth: 200,
      valueGetter({ row }) {
        return row.name;
      },
    },
    {
      field: 'client.username',
      headerName: 'Cliente',
      flex: 1,
      minWidth: 200,
      valueGetter({ row }) {
        return row.client?.username;
      },
    },
    {
      field: 'step.name',
      headerName: 'Etapa',
      headerAlign: 'center',
      align: 'center',
      minWidth: 120,
      renderCell({ row }) {
        return (
          <div>
            <Typography variant="body2" align="center">
              {row.step?.name}
            </Typography>
            <Typography variant="body2" align="center">
              {formatDate(row.funnel_step_entered_at, 'dd/MM/yyyy HH:mm')}
            </Typography>
          </div>
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'center',
      renderCell({ row }) {
        return (
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            gap={1}
            color={opportunityStatusTextColor(row)}
          >
            <Typography fontSize="inherit">
              {OPPORTUNITY_STATUS_LABEL[row.status]}
            </Typography>

            <OpportunityHasProducts opportunity={row} />

            <OpportunityUnread
              opportunity={row}
              messageUnread={
                !!responseUnreads?.data.find(
                  (unread) => unread.client_id === row.client_id,
                )
              }
            />

            {row.is_waiting && row.wait_until && (
              <Tooltip title={`Em espera até ${formatDate(row.wait_until)}`}>
                <AccessTimeIcon fontSize="inherit" />
              </Tooltip>
            )}
          </Grid>
        );
      },
    },
    {
      field: 'last_interest_at',
      headerName: 'Últ. Interesse',
      ...columnDateTime,
    },
    {
      field: 'attendant.name',
      headerName: 'Atendente',
      headerAlign: 'center',
      align: 'center',
      valueGetter({ row }) {
        return row.attendant?.name;
      },
    },
    {
      field: 'last_attendance_at',
      headerName: 'Últ. Atendimento',
      ...columnDateTime,
      minWidth: 130,
    },
  ];

  useEffect(() => {
    async function handleReload() {
      await refetch();
      setNeedsReload(false);
    }

    if (needsReload) handleReload();
  }, [needsReload, refetch, setNeedsReload]);

  useEffect(() => {
    setOpportunitiesTotals({
      qty: response?.data.data.length || 0,
      sum: response?.data.data.reduce((sum, opp) => sum + opp.amount, 0) || 0,
    });
  }, [response, setOpportunitiesTotals]);

  return (
    <StyledTable
      components={{ Toolbar: null }}
      rows={response?.data.data || []}
      pageSize={response?.data.meta.per_page}
      rowCount={response?.data.meta.total}
      columns={columns}
      loading={isLoading}
      pagination
      paginationMode="server"
      onPageChange={handlePageChange}
      onPageSizeChange={handlePageSizeChange}
      getRowClassName={() => 'cursor-pointer'}
      onRowClick={handleRowClick}
    />
  );
};

export default ViewList;
