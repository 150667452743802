import React, { useContext, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import CheckIcon from '@mui/icons-material/Check';
import ForwardIcon from '@mui/icons-material/Forward';
import {
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  Grid,
  Paper,
} from '@mui/material';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import Button from 'src/components/Button';
import AutocompleteUsers from 'src/components/Form/_Common/AutocompleteUsers';
import yup from 'src/libs/yup';
import OmnichannelContext from 'src/pages/Private/Omnichannel/context';
import WSOmnichannel from 'src/services/websocket/omnichannel';
import { handleApiResponseErrors, showFormErrors } from 'src/utils/errors';
import yupValidate from 'src/utils/yupValidate';

const ChatTransferSchema = yup.object().shape({
  attendant_id: yup
    .number()
    .nullable()
    .required()
    .label('Atendente Responsável'),
});

const ChatAttendantsSchema = yup.object().shape({
  attendants: yup
    .array()
    .of(yup.number().required())
    .nullable()
    .label('Atendentes'),
});

export type ChatAttendantsDialogProps = {
  onClose: () => void;
  onSuccessTransfer?: () => void;
  onSuccessAttendants?: () => void;
} & DialogProps;

const ChatAttendantsDialog: React.FC<ChatAttendantsDialogProps> = ({
  onSuccessTransfer,
  onSuccessAttendants,
  onClose,
  ...rest
}) => {
  const { selectedChat } = useContext(OmnichannelContext);
  const [loading, setLoading] = useState(false);
  const transferFormRef = useRef<FormHandles>(null);
  const attendantsFormRef = useRef<FormHandles>(null);
  const { user } = useSelector((state) => state.auth);

  const canUpdateAttendants =
    selectedChat?.attendant_id === user?.id || user?.is_admin;

  const isChatActive = selectedChat?.status === 'active';

  function handleClose() {
    if (loading) return;

    onClose();
  }

  async function handleSubmitTransfer(formData) {
    try {
      setLoading(true);

      const { success, data, errors } = await yupValidate(
        ChatTransferSchema,
        formData,
      );

      if (!success) {
        return showFormErrors(errors, transferFormRef);
      }

      if (selectedChat) {
        WSOmnichannel.socket?.emit(
          'transfer_chat',
          {
            chat_id: selectedChat?._id,
            attendant_id: data.attendant_id,
          },
          (result) => {
            if (result.error) {
              toast.error(result.error.message);
            } else {
              if (onSuccessTransfer) onSuccessTransfer();
              toast.success('Chat transferido!');
              onClose();
            }
          },
        );
      }
    } catch (error) {
      handleApiResponseErrors(
        error.response,
        'Erro ao transferir chat.',
        ChatTransferSchema,
        transferFormRef,
      );
    } finally {
      setLoading(false);
    }
  }

  async function handleSubmitAttendants(formData) {
    try {
      setLoading(true);

      const { success, data, errors } = await yupValidate(
        ChatAttendantsSchema,
        formData,
      );

      if (!success) return showFormErrors(errors, attendantsFormRef);

      if (selectedChat) {
        WSOmnichannel.socket?.emit(
          'chat_attendants',
          {
            chat_id: selectedChat._id,
            attendants_ids: data.attendants || [],
          },
          (result) => {
            if (result.error) {
              toast.error(result.error.message);
            } else {
              if (onSuccessAttendants) onSuccessAttendants();
              toast.success('Atendentes atualizados!');
              onClose();
            }
          },
        );
      }
    } catch (error) {
      handleApiResponseErrors(
        error.response,
        'Erro ao atualizar atendentes.',
        ChatAttendantsSchema,
        transferFormRef,
      );
    } finally {
      setLoading(false);
    }
  }

  return (
    <Dialog fullWidth maxWidth="sm" onClose={handleClose} {...rest}>
      <DialogTitle align="center">Atendentes com acesso ao Chat</DialogTitle>

      <DialogContent>
        <Grid container spacing={3} marginTop={0}>
          <Grid item xs={12}>
            <Paper elevation={6}>
              <Form
                ref={transferFormRef}
                onSubmit={handleSubmitTransfer}
                initialData={{
                  attendant_id: selectedChat?.attendant_id,
                }}
              >
                <Grid container spacing={1} padding={1}>
                  <Grid item xs={12}>
                    <Divider textAlign="left">Transferir Chat</Divider>
                  </Grid>

                  <Grid item xs={12}>
                    <AutocompleteUsers
                      name="attendant_id"
                      label="Atendante Responsável"
                      disabled={
                        loading || !canUpdateAttendants || !isChatActive
                      }
                    />
                  </Grid>

                  <Grid item xs={12} textAlign="right">
                    <Button
                      type="submit"
                      endIcon={<ForwardIcon />}
                      color="success"
                      disabled={
                        loading || !canUpdateAttendants || !isChatActive
                      }
                      variant="contained"
                    >
                      Transferir
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper elevation={6}>
              <Form
                ref={attendantsFormRef}
                onSubmit={handleSubmitAttendants}
                initialData={{
                  attendants: selectedChat?.attendants_ids,
                }}
              >
                <Grid container spacing={1} padding={1}>
                  <Grid item xs={12}>
                    <Divider textAlign="left">Atendentes</Divider>
                  </Grid>

                  <Grid item xs={12}>
                    <AutocompleteUsers
                      name="attendants"
                      label="Atendentes Extras"
                      multiple
                      getOptionDisabled={(option) =>
                        option.value === selectedChat?.attendant_id
                      }
                      disabled={
                        loading || !canUpdateAttendants || !isChatActive
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      endIcon={<CheckIcon />}
                      color="success"
                      disabled={
                        loading || !canUpdateAttendants || !isChatActive
                      }
                      variant="contained"
                    >
                      Salvar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ChatAttendantsDialog;
